import LoadingSpinner from '@/components/LoadingSpinner';
import { useDfMediaQuery } from '@/utils/dfMediaQuery';
import { useSelector } from '@umijs/max';
import { Button, Form, Input, Modal, theme, Tooltip, Typography } from 'antd';
import { Suspense, useEffect, useRef, useState } from 'react';
import { useFragment } from 'react-relay';
import { useMonitorContext } from '../../MonitorContext';
import {
  useSceneAddActions,
  useSceneUpdateActions,
} from '../../MonitorMutations';
import { getSceneName } from '../../utils';
import type { MonitorMutations_SceneUpdate_Mutation$data } from '../../__generated__/MonitorMutations_SceneUpdate_Mutation.graphql';
import type { MonitorMutations_UserSceneConnectionAdd_Mutation$data } from '../../__generated__/MonitorMutations_UserSceneConnectionAdd_Mutation.graphql';
import { SceneFragment } from '../Scene';
import type {
  SceneFragment$data,
  SceneFragment$key,
} from '../Scene/__generated__/SceneFragment.graphql';
import { ChannelsSelection } from './ChannelsSelection';
import { SceneTimeline } from './SceneTimeline';
import { SceneViewerContainer, StyledSider } from './styles';

export interface SceneViewerProps {
  selectedSceneRef?: SceneFragment$key;
  isCameraListHidden?: boolean;
  passedChannelIds?: string[];
  openSaveSceneModal?: boolean;
  onSceneSave?: (
    scene:
      | MonitorMutations_UserSceneConnectionAdd_Mutation$data
      | MonitorMutations_SceneUpdate_Mutation$data,
  ) => void;
  onModalClose?: () => void;
}

const SceneViewerContent = ({
  selectedSceneRef,
  isCameraListHidden,
  passedChannelIds,
  openSaveSceneModal,
  onSceneSave,
  onModalClose,
}: SceneViewerProps) => {
  const timelinePlayerRef = useRef();
  const { isMobile } = useDfMediaQuery();
  const { appId, currentUserId } = useMonitorContext();
  const { token } = theme.useToken();

  const { ch } = useSelector((state) => ({
    // @ts-expect-error
    ch: state.locations.ch,
  }));

  const [selectedChannels, setSelectedChannels] = useState<
    string[] | undefined
  >(passedChannelIds ?? []);

  const selectedSceneData = useFragment(SceneFragment, selectedSceneRef);
  const viewingExistingScene = !!selectedSceneData;

  useEffect(
    () => setSelectedChannels(selectedSceneData?.channelIDs as string[]),
    [selectedSceneData],
  );

  const newChannelsSelected =
    (selectedSceneData?.channelIDs?.length ?? 0) !==
    (selectedChannels?.length ?? 0);

  const isSceneViewingMode = !!selectedChannels?.length && !newChannelsSelected;

  const [form] = Form.useForm();

  const { updateSceneNameAndChannels, sceneUpdateMutationPending } =
    useSceneUpdateActions({
      scene: selectedSceneData as SceneFragment$data,
      appId,
    });

  const { addUserScene, addUserSceneMutationPending } = useSceneAddActions({
    appId,
    userId: currentUserId,
  });

  const getAnchorTimestamp = () => {
    // @ts-expect-error
    let timestamp = timelinePlayerRef.current?.state.currentPlayTime;
    if (timestamp) {
      // db doesn't like floats...
      timestamp = Math.floor(timestamp) + '';
    }

    return timestamp;
  };

  const handleSceneCreateUpdateViaModal = (updatedSceneName: string) => {
    if (viewingExistingScene) {
      updateSceneNameAndChannels({
        name: updatedSceneName,
        channelIDs: selectedChannels as string[],
        anchorTimeStamp: getAnchorTimestamp(),
        onSceneUpdated: (response) => {
          onSceneSave?.(response);
        },
      });
    } else {
      addUserScene({
        channelIds: selectedChannels as string[],
        name: updatedSceneName,
        anchorTimeStamp: getAnchorTimestamp(),
        onSceneAdded: (response) => {
          onSceneSave?.(response);
        },
      });
    }
  };

  const saveSceneModalContent = (
    <Modal
      open={openSaveSceneModal}
      onCancel={() => onModalClose?.()}
      title={viewingExistingScene ? 'Update Scene' : 'Create Scene'}
      footer={null}
      centered>
      <Form
        form={form}
        onFinish={(values) => {
          handleSceneCreateUpdateViaModal(values.sceneName);
        }}
        initialValues={{
          sceneName: viewingExistingScene
            ? selectedSceneData?.name ??
              getSceneName(selectedSceneData?.channelIDs as string[], ch)
            : 'New Scene',
        }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <Typography.Text>
            Save this scene for quick viewing later under the ‘Team Scenes’ tab.
          </Typography.Text>
          <Form.Item name="sceneName" style={{ marginBottom: 0 }}>
            <Input />
          </Form.Item>
          <div style={{ display: 'flex', gap: '20px' }}>
            <Button onClick={() => onModalClose?.()} style={{ width: '100%' }}>
              Discard Changes
            </Button>
            <Tooltip
              title={
                selectedChannels?.length === 0 ? 'No cameras selected' : ''
              }
              color={token.colorError}>
              <Button
                disabled={selectedChannels?.length === 0}
                type="primary"
                htmlType="submit"
                style={{ width: '100%' }}
                loading={
                  addUserSceneMutationPending || sceneUpdateMutationPending
                }>
                {viewingExistingScene ? 'Save Scene' : 'Create Scene'}
              </Button>
            </Tooltip>
          </div>
        </div>
      </Form>
    </Modal>
  );

  return (
    <SceneViewerContainer>
      <SceneTimeline
        timelinePlayerRef={timelinePlayerRef}
        selectedChannels={selectedChannels}
        selectedSceneData={selectedSceneData}
      />
      <StyledSider
        trigger={null}
        breakpoint="md"
        collapsedWidth="0"
        collapsible={true}
        collapsed={isCameraListHidden}
        width={isMobile ? 'calc(100vw - 15px)' : 282}
        style={{ background: 'transparent' }}>
        <ChannelsSelection
          selectedChannels={selectedChannels}
          passedChannelIds={passedChannelIds}
          isSceneViewingMode={isSceneViewingMode}
          setSelectedChannels={setSelectedChannels}
        />
      </StyledSider>
      {saveSceneModalContent}
    </SceneViewerContainer>
  );
};

const SceneViewer = (props: SceneViewerProps) => {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <SceneViewerContent {...props} />
    </Suspense>
  );
};

export default SceneViewer;
