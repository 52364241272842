import type { SubHeader } from '@/components/AppHeader/SubHeader';
import LoadingSpinner from '@/components/LoadingSpinner';
import { getCurrentCustomerID } from '@/utils/utils';
import { Typography } from 'antd';
import React, { createContext, useMemo, useState } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { useSelector } from 'umi';
import { MonitorAppId } from './constants';
import type {
  MonitorContextQuery,
  MonitorContextQuery$data,
} from './__generated__/MonitorContextQuery.graphql';

const { Text, Title } = Typography;

type SubHeaderProperties = Pick<
  React.ComponentProps<typeof SubHeader>,
  'auxControls' | 'backPath' | 'onBackClick'
>;
interface MonitorContextState {
  monitorAppQueryData: MonitorContextQuery$data;
  appId: number;
  customerId: number;
  currentUserId: number;
  selectedChannels?: string[];
  subHeaderProperties?: SubHeaderProperties;
  setSubHeaderProperties: React.Dispatch<
    React.SetStateAction<SubHeaderProperties | undefined>
  >;
}

// Create the context
export const MonitorContext = createContext<MonitorContextState | undefined>(
  undefined,
);

interface MonitorProviderProps {
  children: JSX.Element;
}

const MonitorQuery = graphql`
  query MonitorContextQuery($app_id: Int!, $customer_id: Int!) {
    monitorTeamScenes(appId: $app_id, customerId: $customer_id) {
      teamScenesData: scenesData {
        __id
        ...TeamScenesFragment
          @arguments(app_id: $app_id, customer_id: $customer_id)
      }
    }
    monitorUserScenes(appId: $app_id, customerId: $customer_id) {
      userScenesData: scenesData {
        __id
        ...MyScenesFragment
          @arguments(app_id: $app_id, customer_id: $customer_id)
      }
    }
  }
`;

export const MonitorProvider = ({ children }: MonitorProviderProps) => {
  const customerId = useMemo(() => getCurrentCustomerID(), []);
  const currentUserId = useSelector(
    // @ts-expect-error
    (state) => state.user.currentUser.UserID,
  );
  const [subHeaderProperties, setSubHeaderProperties] =
    useState<SubHeaderProperties>();

  const monitorAppQueryData = useLazyLoadQuery<MonitorContextQuery>(
    MonitorQuery,
    {
      app_id: MonitorAppId,
      customer_id: customerId,
    },
  );

  const allApps = useSelector((state: any) => state.apps);
  const allAppsDataLoading = useSelector(
    (state: any) => state.loading.effects['apps/fetchAllApps'],
  );
  const isMonitorAppInstalled =
    allApps.all.filter((app: any) => app.AppID === MonitorAppId).length > 0;

  if (allAppsDataLoading) {
    return <LoadingSpinner />;
  }

  if (!isMonitorAppInstalled) {
    return (
      <div
        style={{
          marginTop: '64px',
          textAlign: 'center',
        }}>
        <Title level={3}>App not installed</Title>
        <Text strong>
          Ask your Account Manager for Live View App access for this
          functionality
        </Text>
      </div>
    );
  }

  return (
    <MonitorContext.Provider
      value={{
        monitorAppQueryData,
        appId: MonitorAppId,
        customerId,
        currentUserId,
        subHeaderProperties,
        setSubHeaderProperties,
      }}>
      {children}
    </MonitorContext.Provider>
  );
};

export const useMonitorContext = () => {
  const context = React.useContext(MonitorContext);
  if (context === undefined) {
    throw new Error('useVMSPlusContext must be used within a VMSPlusProvider');
  }
  return context;
};
