import { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import React from 'react';
import styles from './styles.less';

type ZoomControlsProps = {
  videoContainerRef: React.RefObject<HTMLDivElement>; //Container which will have scrollbars shown
  videoDivRef:
    | React.RefObject<HTMLDivElement>
    | React.RefObject<HTMLVideoElement>; //Div inside the container with black bars to match resolution
  onZoom?: (scale: number) => void;
  className?: string;
};

const ZoomControls: React.FC<ZoomControlsProps> = ({
  videoContainerRef,
  videoDivRef,
  onZoom,
  className,
}) => {
  const MAX_ZOOM = 8;
  const MIN_ZOOM = 1;
  const BASE_ANIMATION_DURATION_MS = 100;
  const [zoomScale, setZoomScale] = React.useState(MIN_ZOOM);

  const handleZoom = (type: 'in' | 'out' | 'reset') => {
    if (!videoContainerRef.current || !videoDivRef.current) return;

    const videoContainer = videoContainerRef.current;
    const videoDiv = videoDivRef.current;
    let targetScale = zoomScale;

    if (type === 'in' && zoomScale < MAX_ZOOM) {
      targetScale += 1;
    } else if (type === 'out' && zoomScale > 1) {
      targetScale -= 1;
    } else if (type === 'reset') {
      targetScale = 1;
    }

    setZoomScale(targetScale);

    const initialScale = zoomScale;
    const scaleChange = targetScale - initialScale;

    // Calculate the initial visible center relative to the scroll position
    const visibleCenterX =
      videoContainer.scrollLeft + videoContainer.clientWidth / 2;
    const visibleCenterY =
      videoContainer.scrollTop + videoContainer.clientHeight / 2;

    // scale duration to scaleChange to have a constant percieved zoom speed
    const duration = Math.abs(scaleChange) * BASE_ANIMATION_DURATION_MS;
    const startTime = performance.now();

    const animate = (currentTime: number) => {
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / duration, 1);

      // Interpolate the scale
      const newScale = initialScale + scaleChange * progress;
      videoDiv.style.width = `${newScale * 100}%`;
      videoDiv.style.height = `${newScale * 100}%`;

      // Calculate the new scroll positions based on the updated scale
      const scaleRatio = newScale / initialScale;
      const newScrollLeft =
        visibleCenterX * scaleRatio - videoContainer.clientWidth / 2;
      const newScrollTop =
        visibleCenterY * scaleRatio - videoContainer.clientHeight / 2;

      // Apply the calculated scroll position
      videoContainer.scrollLeft = newScrollLeft;
      videoContainer.scrollTop = newScrollTop;

      if (progress < 1) {
        requestAnimationFrame(animate);
      } else {
        onZoom?.(targetScale);
      }
    };

    requestAnimationFrame(animate);
  };

  return (
    <div className={classnames(className, styles['zoom-container'])}>
      <ZoomInOutlined
        style={{
          color: zoomScale >= MAX_ZOOM ? 'grey' : 'white',
          cursor: 'pointer',
          fontSize: 12,
        }}
        onClick={() => handleZoom('in')}
      />
      <div
        style={{ padding: '0 4px', fontSize: 12, cursor: 'pointer' }}
        onClick={() => handleZoom('reset')}>
        &bull;
      </div>
      <ZoomOutOutlined
        style={{
          color: zoomScale === MIN_ZOOM ? 'grey' : 'white',
          cursor: 'pointer',
          fontSize: 12,
        }}
        onClick={() => handleZoom('out')}
      />
    </div>
  );
};

export default ZoomControls;
