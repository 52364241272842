import LoadingSpinner from '@/components/LoadingSpinner';
import { useDfMediaQuery } from '@/utils/dfMediaQuery';
import { useAppCapabilities } from '@/utils/useAppCapabilities';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Navigate } from '@umijs/max';
import { Button, Flex } from 'antd';
import { Suspense, useMemo, useState } from 'react';
import type { SceneFragment$key } from '../components/Scene/__generated__/SceneFragment.graphql';
import type { SceneViewerProps } from '../components/SceneViewer';
import SceneViewer from '../components/SceneViewer';
import {
  CAPABILITIES,
  CAPABILITIES_MAP,
  MonitorAppPathname,
} from '../constants';
import { useMonitorContext } from '../MonitorContext';
import { useMonitorAppSubHeaderAuxControls } from '../useMonitorAppSubHeaderAuxControls';
import type { MonitorMutations_UserSceneConnectionAdd_Mutation$data } from '../__generated__/MonitorMutations_UserSceneConnectionAdd_Mutation.graphql';

const ActiveSceneContent = () => {
  const { isMobile } = useDfMediaQuery();
  const { appId } = useMonitorContext();
  const { tabsToShow } = useAppCapabilities({
    appId,
    allAppCapabilities: CAPABILITIES,
  });
  // Define the state variables and functions you want to expose in the context
  const [isSidebarHidden, setIsSidebarHidden] = useState(isMobile);
  const [openSaveSceneModal, setOpenSaveSceneModal] = useState(false);
  const [selectedSceneRef, setSelectedSceneRef] = useState<SceneFragment$key>();

  const toggleSidebarVisibility = () => {
    setIsSidebarHidden((prev) => !prev);
  };

  const handleSceneSave: SceneViewerProps['onSceneSave'] = (scene) => {
    const newScene = (
      scene as MonitorMutations_UserSceneConnectionAdd_Mutation$data
    )?.createNewUserScene?.sceneEdge?.node;
    setSelectedSceneRef(newScene as SceneFragment$key);
    setOpenSaveSceneModal(false);
  };

  const handleModalClose = () => {
    setOpenSaveSceneModal(false);
  };

  const passedChannelIds = useMemo(
    () => new URLSearchParams(location.search).getAll('channelIds'),
    [location.search],
  );

  useMonitorAppSubHeaderAuxControls(
    {
      auxControls: (
        <Flex gap={20}>
          <Button onClick={() => setOpenSaveSceneModal(true)} type="primary">
            Create Scene
          </Button>
          {isSidebarHidden ? (
            <MenuFoldOutlined
              onClick={toggleSidebarVisibility}
              style={{ fontSize: '24px' }}
            />
          ) : (
            <MenuUnfoldOutlined
              onClick={toggleSidebarVisibility}
              style={{ fontSize: '24px' }}
            />
          )}
        </Flex>
      ),
    },
    [isSidebarHidden],
  );

  if (tabsToShow.indexOf(CAPABILITIES_MAP.activeScene) === -1) {
    return <Navigate to={MonitorAppPathname} />;
  }

  return (
    <SceneViewer
      selectedSceneRef={selectedSceneRef}
      passedChannelIds={passedChannelIds}
      openSaveSceneModal={openSaveSceneModal}
      onModalClose={handleModalClose}
      onSceneSave={handleSceneSave}
      isCameraListHidden={isSidebarHidden}
    />
  );
};

const ActiveScene = () => {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <ActiveSceneContent />
    </Suspense>
  );
};

export default ActiveScene;
