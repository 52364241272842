import { Flex, notification, Popconfirm, Popover, Typography } from 'antd';
import {
  SceneCardContainer,
  SceneChannelThumbnailsContainer,
  SceneDetailsContainer,
  SceneDetailsTimestampText,
  SceneTitleNameEditIcon,
  ScreenDetailsTitleText,
  ShareUnshareIconContainer,
} from './styles';

import { isUserAdmin } from '@/utils/utils';
import { DeleteOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import { useMemo, useState } from 'react';
import { graphql, useFragment } from 'react-relay';
import { useSelector } from 'umi';
import { SceneTypesMap } from '../../constants';
import {
  useSceneRemoveActions,
  useSceneUpdateActions,
} from '../../MonitorMutations';
import type { SceneType } from '../../types';
import { getSceneName } from '../../utils';
import { ShareWithTeam } from './ShareWithTeam';
import { TileThumbnail } from './TileThumbnail';
import { UnShareWithTeam } from './UnShareWithTeam';
import type {
  SceneFragment$data,
  SceneFragment$key,
} from './__generated__/SceneFragment.graphql';

interface SceneProps {
  appId: number;
  customerId: number;
  sceneKey: SceneFragment$key;
  onSelect: (
    sceneRef: SceneFragment$key,
    sceneData: SceneFragment$data,
  ) => void;
  sceneType: SceneType;
  parentConnectionId: string;
}

export const SceneFragment = graphql`
  fragment SceneFragment on MonitorScene @relay(mask: false) {
    id
    name
    isFavorite
    channelIDs
    timestamp
    anchorTimeStamp
    createdBy
    isSharedWithTeam
  }
`;

const Scene = ({
  appId,
  customerId,
  sceneKey,
  onSelect,
  sceneType,
  parentConnectionId,
}: SceneProps) => {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const scene = useFragment(SceneFragment, sceneKey);

  const currentUser = useSelector(
    // @ts-expect-error
    (state) => state.user.currentUser,
  );
  const userID = currentUser.UserID;
  const isCurrentUserOwnerOfScene = scene.createdBy === userID;

  const { removeUserScene } = useSceneRemoveActions({
    scene,
    appId,
  });

  const { updateSceneName } = useSceneUpdateActions({
    scene,
    appId,
  });

  const { ch } = useSelector((state) => ({
    // @ts-expect-error
    ch: state.locations.ch,
  }));

  const tileName = useMemo(
    () => scene.name ?? getSceneName(scene?.channelIDs as string[], ch),
    [scene, ch],
  );

  const deleteSceneDescription = useMemo(() => {
    if (sceneType === SceneTypesMap.TEAM) {
      if (isCurrentUserOwnerOfScene)
        return 'Deleting will remove access for all your team members and will remove the scene from your scenes as well';

      return "Deleting will remove access for all your team members and will remove the scene from owner's scenes as well";
    } else {
      if (scene.isSharedWithTeam)
        return 'This scene is shared with team. Deleting will remove access for all your team members';

      return 'Are you sure you want to delete this scene';
    }
  }, [isCurrentUserOwnerOfScene, scene.isSharedWithTeam, sceneType]);

  const sceneRemoveSuccessMessage = useMemo(() => {
    if (sceneType === SceneTypesMap.TEAM) {
      if (isCurrentUserOwnerOfScene)
        return `${scene.name} deleted successfully from both Team Scenes and your scenes`;

      return `${scene.name} deleted successfully from Team Scenes and owner's scenes`;
    } else {
      if (scene.isSharedWithTeam)
        return `${scene.name} deleted successfully from your scenes and Team Scenes`;

      return `${scene.name} deleted successfully`;
    }
  }, [
    isCurrentUserOwnerOfScene,
    scene.isSharedWithTeam,
    scene.name,
    sceneType,
  ]);

  const showSceneRemoveSuccessNotification = () => {
    notification.success({
      message: sceneRemoveSuccessMessage,
      placement: 'bottomRight',
    });
  };

  const showSceneRemoveErrorNotification = () => {
    notification.error({
      message: `${scene.name} could not be deleted`,
      placement: 'bottomRight',
    });
  };

  const handleTileClick = () => {
    onSelect(sceneKey, scene);
  };

  const handleTileTitleChange = (text: string) => {
    if (text !== tileName) {
      updateSceneName({ name: text });
    }
  };

  const handleMouseOverCardDetails = () => {
    setIsMouseOver(true);
  };

  const handleMouseLeaveCardDetails = () => {
    setIsMouseOver(false);
  };

  const handleRemoveSceneConfirmed = () => {
    removeUserScene({
      connectionId: parentConnectionId,
      onUserSceneRemoveSuccess: showSceneRemoveSuccessNotification,
      onUserSceneRemoveError: showSceneRemoveErrorNotification,
    });
  };

  return (
    <SceneCardContainer
      onMouseOver={handleMouseOverCardDetails}
      onMouseLeave={handleMouseLeaveCardDetails}>
      <SceneChannelThumbnailsContainer onClick={handleTileClick}>
        <TileThumbnail
          appId={appId}
          customerId={customerId}
          channelIds={scene?.channelIDs.slice(0, 8) as string[]}
        />
      </SceneChannelThumbnailsContainer>
      <SceneDetailsContainer>
        <Flex gap={16} align="baseline" justify="space-between">
          <ScreenDetailsTitleText
            editable={
              isMouseOver && isCurrentUserOwnerOfScene
                ? {
                    onChange: handleTileTitleChange,
                    icon: <SceneTitleNameEditIcon />,
                    autoSize: { maxRows: 2 },
                  }
                : false
            }
            ellipsis={{ rows: 2, expandable: false }}
            onClick={(e) => e?.stopPropagation()}>
            {tileName}
          </ScreenDetailsTitleText>
          {isMouseOver &&
            (isCurrentUserOwnerOfScene || isUserAdmin(currentUser) ? (
              <Popconfirm
                title="Delete Scene?"
                onConfirm={handleRemoveSceneConfirmed}
                description={deleteSceneDescription}
                okText="Yes"
                cancelText="No"
                overlayStyle={{ maxWidth: '350px' }}>
                <DeleteOutlined
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                />
              </Popconfirm>
            ) : (
              <Popover
                content={
                  <Typography.Text style={{ color: 'red' }}>
                    This can only be deleted by the scene owner
                  </Typography.Text>
                }
                trigger="click">
                <DeleteOutlined />
              </Popover>
            ))}
          {isMouseOver && (
            <ShareUnshareIconContainer>
              {sceneType === SceneTypesMap.TEAM && (
                <UnShareWithTeam scene={scene} />
              )}
              {sceneType === SceneTypesMap.USER && (
                <ShareWithTeam scene={scene} />
              )}
            </ShareUnshareIconContainer>
          )}
        </Flex>
        {scene?.timestamp && (
          <SceneDetailsTimestampText>
            Last updated {moment(Number(scene?.timestamp) * 1000).fromNow()}
          </SceneDetailsTimestampText>
        )}
      </SceneDetailsContainer>
    </SceneCardContainer>
  );
};
export { Scene };
