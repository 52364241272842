import { useSelector } from '@umijs/max';
import _ from 'lodash';

interface UseCapabilities {
  appId: number;
  allAppCapabilities: string[];
}

/**
 * This custom hook can be used to get the capabilities of a DF2.0 app.
 * @param param0
 * @returns
 */
const useAppCapabilities = ({ appId, allAppCapabilities }: UseCapabilities) => {
  const appConfig = useSelector(
    // @ts-expect-error
    (state) => state.apps.all.filter((a) => a.AppID == appId)[0],
  );

  let tabsToShow = allAppCapabilities;
  const capabilities = _.get(appConfig, 'capabilities', []);

  if (capabilities.indexOf('*') == -1) {
    tabsToShow = tabsToShow.filter((tab) => capabilities.indexOf(tab) !== -1);
  }

  return { tabsToShow };
};

export { useAppCapabilities };
