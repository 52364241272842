import DataList from '@/components/DataList';
import LoadingSpinner from '@/components/LoadingSpinner';
import { Button, Typography } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'umi';

import { APPS } from '@/pages/apps/app/constants/appList';
import { get } from 'lodash';
import AddAccessRule from './add-access-rule';
import DeleteAccessRule from './delete-access-rule';

const userDisplayString = (user: any) => {
  if (!user) return '';
  return `${user.FirstName || ''} ${user.LastName || ''} (${user.Email || ''})`;
};

const appDisplayString = (app: any, appsById: any) => {
  if (app.Id in appsById) {
    return appsById[app.Id].Name;
  }
  return app.Name;
};

const displayList = (list: string[]): string => {
  if (list.length <= 3) {
    return list.join(', ');
  }
  return `${list.slice(0, 3).join(', ')} and ${list.length - 3} more`;
};

const displayCapabilities = (
  selectedCapabilities: string[],
  appId: any,
): string => {
  const appCapabilities = Object.values(get(APPS[appId], 'CAPABILITIES', []));
  if (selectedCapabilities.length === appCapabilities.length) {
    return 'All';
  }
  return selectedCapabilities
    .map((cap) => APPS[appId]?.CAPABILITIES_LABEL_MAP[cap] || cap)
    .join(', ');
};

const AccessRules = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: 'apps/fetchAllApps' });
    dispatch({ type: 'access_rules/fetchAccessRules' });
    dispatch({ type: 'accounts/fetchUsers' });
  }, []);

  const loadingAccessRules = useSelector(
    (state) => state['loading']['effects']['access_rules/fetchAccessRules'],
  );
  const loadingSiteData = useSelector(
    (state) =>
      state['loading']['effects']['sites/fetchSites'] ||
      state['loading']['effects']['labels/fetchLabels'],
  );

  const addingAccessRule = useSelector(
    (state) =>
      state['loading']['effects']['access_rules/createAccessRule'] ||
      state['loading']['effects']['access_rules/updateAccessRule'],
  );

  const access_rules = useSelector((state) => state['access_rules']['all']);
  const apps = useSelector((state) => state['apps']['all']);
  const appsById = useSelector((state) => state['apps']['byID']);
  const users = useSelector((state) => state['accounts']['users']);
  const sites = useSelector((state) => state['sites']['all']);
  const siteGroups = useSelector((state) => state['labels']['all']);

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'baseline',
        }}>
        <Typography.Title level={5}>Roles</Typography.Title>
        {loadingSiteData ? null : (
          <AddAccessRule
            apps={apps}
            users={users}
            sites={sites}
            siteGroups={siteGroups}
            addingAccessRule={addingAccessRule}>
            <Button type="link">Add</Button>
          </AddAccessRule>
        )}
      </div>
      {loadingAccessRules ? (
        <LoadingSpinner />
      ) : (
        <DataList
          columns={[
            {
              title: 'User',
              key: 'User',
              defaultSortOrder: 'ascend',
              filters: users.map(({ User }) => ({
                text: userDisplayString(User),
                value: User.UserID,
              })),
              onFilter: (value, record) => record.User.UserID == value,
              filterSearch: true,
              sorter: (rowA, rowB) => {
                const stringA = userDisplayString(rowA.User);
                const stringB = userDisplayString(rowB.User);
                return stringA.localeCompare(stringB);
              },
              render: (_, record) => {
                return userDisplayString(record.User);
              },
            },
            {
              title: 'App',
              key: 'App',
              filters: apps.map((app) => ({
                text: app.Name,
                value: app.AppID,
              })),
              onFilter: (value, record) => value === record.App.Id,
              filterSearch: true,
              sorter: (rowA, rowB) => {
                const stringA = appDisplayString(rowA.App, appsById);
                const stringB = appDisplayString(rowB.App, appsById);
                return stringA.localeCompare(stringB);
              },
              render: (_, record) => {
                return appDisplayString(record.App, appsById);
              },
            },
            {
              title: 'Sites',
              key: 'Scope',
              filters: sites.map((site) => ({
                text: site.Name,
                value: site.SiteID,
              })),
              onFilter: (value, record) =>
                record.Scopes?.Sites?.some((site) => site.SiteID === value),
              filterSearch: true,
              render: (_, record) => {
                return displayList(
                  record?.Scopes?.Sites.map((s: any) => s.Name as string) || [],
                );
              },
            },
            {
              title: 'Site Groups',
              key: 'siteGroups',
              filters: siteGroups.map((siteGroup) => ({
                text: siteGroup.Name,
                value: siteGroup.LabelID,
              })),
              onFilter: (value, record) =>
                record.Scopes?.Labels?.some((label) => label.LabelID === value),
              filterSearch: true,
              render: (_, record) => {
                return displayList(record.Scopes.Labels.map((s) => s.Name));
              },
            },
            {
              title: 'Capabilities',
              key: 'capabilities',
              render: (_, record) => {
                return displayCapabilities(record.Capabilities, record.App.Id);
              },
            },
            {
              title: 'Actions',
              key: 'actions',
              render: (_, record) => (
                <div>
                  <AddAccessRule
                    access_rule={record}
                    apps={apps}
                    sites={sites}
                    users={users}
                    siteGroups={siteGroups}
                    addingAccessRule={addingAccessRule}>
                    <span className="df-link">Edit</span>
                  </AddAccessRule>
                  &nbsp;&nbsp;
                  <DeleteAccessRule access_rule={record}>
                    <span className="df-link df-error-text">Delete</span>
                  </DeleteAccessRule>
                </div>
              ),
            },
          ]}
          dataList={access_rules}
          pagination={{
            p_size: 10,
            position: ['bottomLeft'],
          }}
        />
      )}
    </>
  );
};
export default AccessRules;
