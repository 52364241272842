import { BellFilled, UserOutlined } from '@ant-design/icons';
import { Flex, Layout, Typography } from 'antd';
import styled, { css } from 'styled-components';
import { Link } from 'umi';

const { Text } = Typography;

const { Header } = Layout;

export const DfHeaderContainer = styled(Header)`
  display: flex;
  flex-direction: column;
  padding: 0;
  height: auto;
`;

export const DfHeaderPrimary = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(104deg, rgba(82, 0, 105, 0) 0%, #520069 100%),
    #191240;
  box-shadow: 0px 2px 16px -6px rgba(9, 10, 11, 0.12);
  padding: 16px 36px;
`;

export const DfSubHeaderContainer = styled.div`
  width: 100%;
`;

const DropdownTriggerContainerCss = css`
  cursor: pointer;
  border-radius: 4px;
  position: relative;
  padding: 10px;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

export const ProfileDropdownTriggerContainer = styled(Flex)`
  ${DropdownTriggerContainerCss}
  transform: translateX(10px);
`;

export const HeaderDropdownTriggerContainer = styled(Flex)`
  ${DropdownTriggerContainerCss}
  transform: translateX(-10px);
  color: white;
`;

export const ProfileDropdownTriggerText = styled(Text)(
  ({ theme }) => `
  color: ${theme.token?.colorBgLayout};
  font-weight: 500;

  @media (max-width: ${theme.token?.screenSM}px) {
    display: none;
  }
`,
);

export const ProfileDropdownTriggerIcon = styled(UserOutlined)(
  ({ theme }) => `
  color: ${theme.token?.colorBgLayout};
`,
);

export const HeaderDropdownMenuLink = styled(Link)<{ isHighLighted?: boolean }>(
  ({ isHighLighted, theme }) => `
  font-size: 18px;
  font-weight: 800;
  color: ${
    isHighLighted ? theme.token?.colorLink : theme.token?.colorText
  } !important;
`,
);

export const HeaderDropdownLogoutLink = styled(HeaderDropdownMenuLink)(
  ({ theme }) => `
  color: ${theme.token?.colorError} !important;
`,
);

export const NotificationSettingsContainer = styled(Flex)`
  ${DropdownTriggerContainerCss}
`;

export const NotificationSettingsText = styled(Text)(
  ({ theme }) => `
  color: ${theme.token?.colorBgLayout};
  font-weight: 500;
  user-select: none;
`,
);

export const NotificationSettingsIcon = styled(BellFilled)<{
  isMuted?: boolean;
}>(
  ({ isMuted = false, theme }) => `
  position: relative;
  color: ${theme.token?.colorBgLayout};
  background: transparent;

  ${
    isMuted
      ? `
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 3px;
        height: 22px;
        background-color: ${theme.token?.colorBgLayout};
        transform: rotate(-45deg) translateX(-2px);
        transform-origin: 0 0;
        border-left: 2px solid #191240;
      }
    `
      : ''
  }
`,
);
