import DataList from '@/components/DataList';
import ArchiveIncident from '@/components/InfoSidebar/SidebarComponents/Archive';
import AlertSidebar from '@/components/InfoSidebar/Sidebars/AlertSidebar';
import LabelAndSiteSelector from '@/components/LabelAndSiteSelector';
import {
  PRIORITY_REVIEW_QUEUE,
  transformTriggered2,
} from '@/utils/notifications';
import {
  getAssigneeData,
  getFlexibleDateFormat,
  interpretClipData,
} from '@/utils/utils';
import { Button } from 'antd';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import DateRangeFilter from '@/components/DataList/DateRangeFilter';
import ReviewQueueCard from '@/components/ReviewQueueCard';
import { useDispatch, useSelector } from 'umi';
import styles from './style.less';

const DEFAULT_PAGE_SIZE = 25;

const CustomViewSwitcher = ({
  handleViewChange,
  currentView,
  areFiltersApplied = false,
}) => {
  const switchToCardView = () =>
    handleViewChange({ target: { value: 'card' } });
  const switchToTableView = () =>
    handleViewChange({ target: { value: 'table' } });

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      {' '}
      {currentView === 'table' ? (
        <Button onClick={switchToCardView}>Switch to Card View</Button>
      ) : (
        <>
          {areFiltersApplied ? (
            <span className="df-warn-text" style={{ margin: '5px' }}>
              Filters applied. Switch to Table View to change filters.
            </span>
          ) : (
            <span style={{ margin: '5px' }}>
              Switch to Table View to apply filters.
            </span>
          )}
          <Button onClick={switchToTableView}>Switch to Table View</Button>
        </>
      )}
    </div>
  );
};

const IncidentsList = ({ app, locations, namespace }) => {
  //Local state
  const [selectedIncident, setSelectedIncident] = useState();
  const [visibleCards, setVisibleCards] = useState({});
  const dispatch = useDispatch();

  const appId = _.get(app, 'app_id');

  const isLoading = useSelector((state) => {
    const loadingEffects = state.loading.effects;
    return (
      loadingEffects[namespace + '/fetchIncidents'] ||
      loadingEffects[namespace + '/fetchIncidentFilters'] ||
      loadingEffects['accounts/fetchUsers']
    );
  });

  const users = useSelector((state) => state.accounts.users);
  const currentUser = useSelector((state) => state.user.currentUser);

  const [filterConfig, setFilterConfig] = useState({
    priority: { defaultSelected: [1, 2, 3, 4, 5] },
  });
  const [paginationConfig, setPaginationConfig] = useState({
    p_number: 1,
    p_size: DEFAULT_PAGE_SIZE,
    showSizeChanger: true,
    showQuickJumper: true,
    pageSizeOptions: [25, 50, 100, 250],
  });

  useEffect(() => {
    const fetchedDataToFilters = (fetchedFilters) => {
      const newConfig = {};
      // map to column.name in datalist
      const mapKeyToColumnName = {
        priorities: 'priority',
        rules: 'rule',
        owners: 'owner',
      };
      for (const [key, options] of Object.entries(fetchedFilters)) {
        newConfig[mapKeyToColumnName[key]] = { options };
      }
      setFilterConfig({ ...filterConfig, ...newConfig });
    };

    dispatch({ type: namespace + '/fetchIncidentFilters' })
      .then((response) => fetchedDataToFilters(response))
      .catch((error) => console.log('Error fetching incident filters:', error));
  }, []);

  const [paramsFetchIncidents, setParamsFetchIncidents] = useState({
    // take initial pagination values from paginationConfig
    pagination: {
      p_number: paginationConfig.p_number,
      p_size: paginationConfig.p_size,
    },
    filters: {},
  });

  const fetchIncidents = async (
    pagination = {},
    filters = {},
    silent = false,
  ) => {
    const actionName = silent ? 'fetchIncidentsNoLoader' : 'fetchIncidents';
    try {
      const payload = {
        pagination: { ...paramsFetchIncidents.pagination, ...pagination },
        filters: {
          ...paramsFetchIncidents.filters,
          ...filters,
        },
      };

      setParamsFetchIncidents(payload);

      const response = await dispatch({
        type: namespace + `/${actionName}`,
        payload,
      });
      return response;
    } catch (error) {
      // Error occurred, set isLoading to false
      console.log('Error fetching incidents:', error);
      throw error; // Rethrow the error to be handled by the caller
    }
  };

  useEffect(() => {
    const pollingInterval = 60000;
    const pollingTimer = setInterval(() => {
      fetchIncidents(
        paramsFetchIncidents.pagination,
        paramsFetchIncidents.filters,
        true,
      );
    }, pollingInterval);

    return () => {
      clearInterval(pollingTimer);
    };
  }, [paramsFetchIncidents]);

  const incidents = _.get(app, 'incidents.list', []);
  const dataList = useMemo(
    () =>
      transformTriggered2({ loc: locations.loc, ch: locations.ch }, incidents),
    [incidents],
  );

  const p_number = _.get(app, 'incidents.p_number', paginationConfig.p_number);
  const p_size = _.get(app, 'incidents.p_size', paginationConfig.p_size);
  const total_pages = _.get(app, 'incidents.total_pages', 1);

  useEffect(
    () =>
      setPaginationConfig({
        ...paginationConfig,
        p_number,
        p_size,
        total_pages,
      }),
    [p_number, p_size, total_pages],
  );

  const onIncidentArchive = (record) => {
    return (reason: string) => {
      dispatch({
        type: namespace + '/archiveIncident',
        payload: {
          reason,
          ...record,
        },
      });
    };
  };
  const incidentsJsx = useMemo(
    () => (
      <DataList
        isLoading={isLoading}
        dataList={dataList}
        columns={[
          {
            title: 'Alarm Name',
            render: (_e, record) => {
              return _.get(record, 'ruleName');
            },
            key: 'rule',
            filters: filterConfig['rule']?.options,
          },
          // {
          //   title: 'Site',
          //   render: (_e, record) => {
          //     return '';
          //   },
          //   key: 'time',
          // },
          {
            title: 'Camera',
            render: (_e, record) => {
              return (
                _.get(record, 'ChannelID') &&
                locations.ch.byId[_.get(record, 'ChannelID')].Name
              );
            },
            key: 'camera',
          },
          {
            title: 'At',
            render: (_e, record) => {
              return getFlexibleDateFormat(
                record.timeframeStartMoment,
                false,
                false,
                'Asia/Kolkata',
              );
            },
            renderForExport: (_e, record) => {
              return record.timeframeStartMoment.format('YYYY-MM-DD HH:mm z');
            },
            key: 'timestamp',
            filterDropdown: (props) => <DateRangeFilter {...props} />,
          },
          {
            title: 'Priority',
            key: 'priority',
            defaultFilteredValue: [1, 2, 3, 4, 5],
            filters: filterConfig['priority']?.options,
            render: (_e, record) => {
              const priority = _.get(record, 'priority');
              return priority === PRIORITY_REVIEW_QUEUE
                ? 'In Review'
                : priority;
            },
          },
          {
            title: 'Owner',
            key: 'owner',
            filters: getAssigneeData(users, currentUser, true).map(
              ({ label, value }) => {
                return {
                  text: label,
                  value,
                };
              },
            ),
            render: (_e, record) => {
              return _.get(record, 'statusOwnerName');
            },
          },
          {
            title: 'Actions',
            key: 'actions',
            excludeFromExport: true,
            render: (_e, record) => {
              return (
                <span>
                  <span
                    className="df-link"
                    onClick={() => setSelectedIncident(record)}>
                    View
                  </span>
                  &nbsp;&nbsp;
                  <ArchiveIncident
                    incident={record}
                    onArchive={onIncidentArchive(record)}>
                    <span className="df-link df-error-text">Archive</span>
                  </ArchiveIncident>
                </span>
              );
            },
          },
        ]}
        rowClassName={(record) => {
          return `${
            record.id === selectedIncident?.id ? styles['selected-row'] : ''
          }`;
        }}
        name={appId + '-incidents'}
        onChange={(newPagination, newFilters) => {
          fetchIncidents(newPagination, newFilters);
        }}
        isControlled={true}
        pagination={paginationConfig}
        cardMap={{
          content: (record, index) => {
            const info = interpretClipData(
              record,
              locations,
              record?.timezone,
              record?.searchResults?.clips,
            );
            return (
              <ReviewQueueCard
                alert={info}
                alertTitle={<div>{_.get(record, 'ruleName')}</div>}
                controls={
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <Button
                      className="df-link"
                      onClick={() => setSelectedIncident(record)}>
                      View Details
                    </Button>
                    &nbsp;&nbsp;
                    <Button>
                      <ArchiveIncident
                        incident={record}
                        onArchive={onIncidentArchive(record)}>
                        <span className="df-link df-error-text">Archive</span>
                      </ArchiveIncident>
                    </Button>
                  </div>
                }
                style={
                  _.get(record, 'priority') === PRIORITY_REVIEW_QUEUE
                    ? { border: '2px solid red' }
                    : undefined
                }
                // If card is first or it was added to visiblecards by clicking on it, have it visible
                timelineIsVisible={index === 0 || !!visibleCards[record.id]}
                onTimeLineClick={() =>
                  setVisibleCards({ ...visibleCards, [record.id]: true })
                }
              />
            );
          },
        }}
        ViewSwitcher={CustomViewSwitcher}
        exportedFilename="incidents"
      />
    ),
    [
      isLoading,
      dataList,
      paginationConfig,
      filterConfig,
      visibleCards,
      selectedIncident,
    ],
  );

  return (
    <div>
      {
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '12px',
          }}>
          <LabelAndSiteSelector
            app_id={appId}
            onSitesChange={(newSites) =>
              fetchIncidents(
                { p_number: 1 },
                {
                  site_ids: newSites,
                },
              )
            }
          />
        </div>
      }

      {selectedIncident && (
        <AlertSidebar
          clip={selectedIncident}
          searchResults={selectedIncident?.searchResults?.clips}
          appId={appId}
          onClose={() => {
            setSelectedIncident(null);
          }}
          onAfterClipUpdate={(newClip) => {
            if (newClip.isDeleted) {
              dispatch({
                type: namespace + '/archiveIncident',
                payload: {
                  ...newClip,
                },
              });
              setSelectedIncident(null);
            } else {
              dispatch({
                type: `${namespace}/fetchIncidents`,
                payload: {
                  filters: paramsFetchIncidents.filters,
                  pagination: paramsFetchIncidents.pagination,
                },
              });
            }
          }}
        />
      )}
      {incidentsJsx}
    </div>
  );
};

export default IncidentsList;
