import { SubHeaderTitleText } from '@/components/AppHeader/SubHeader/styles';
import { useDfSubHeader } from '@/components/AppHeader/utils';
import LoadingSpinner from '@/components/LoadingSpinner';
import { DfThemeConfigProvider } from '@/layouts/DfThemeConfigProvider';
import { useAppCapabilities } from '@/utils/useAppCapabilities';
import { Suspense, useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router';
import {
  CAPABILITIES,
  CAPABILITIES_LABEL_MAP,
  CAPABILITIES_MAP,
  MonitorAppPathname,
} from './constants';
import { MonitorProvider, useMonitorContext } from './MonitorContext';
import { MonitorAppContainer } from './styles';

const Monitor = () => {
  const { subHeaderProperties, appId } = useMonitorContext();
  const { renderSubHeader } = useDfSubHeader();
  const { tabsToShow } = useAppCapabilities({
    appId,
    allAppCapabilities: CAPABILITIES,
  });
  const navigate = useNavigate();
  const location = useLocation();

  const pathname = location.pathname;

  const activeTabIndex = useMemo(() => {
    switch (pathname) {
      case `${MonitorAppPathname}/active-scene`:
        return tabsToShow.indexOf(CAPABILITIES_MAP.activeScene);
      case `${MonitorAppPathname}/my-scenes`:
        return tabsToShow.indexOf(CAPABILITIES_MAP.myScenes);
      case `${MonitorAppPathname}/team-scenes`:
        return tabsToShow.indexOf(CAPABILITIES_MAP.teamScenes);
    }

    return 0;
  }, [pathname, tabsToShow]);

  const subHeaderTabItems = useMemo(
    () =>
      tabsToShow.map((tab) => ({
        title:
          CAPABILITIES_LABEL_MAP[tab as keyof typeof CAPABILITIES_LABEL_MAP],
        onClick: () => {
          navigate(`${MonitorAppPathname}/${tab}`);
        },
      })),
    [navigate, tabsToShow],
  );

  return (
    <MonitorAppContainer>
      {renderSubHeader({
        title: <SubHeaderTitleText>Monitor</SubHeaderTitleText>,
        tabs: {
          items: subHeaderTabItems,
          activeTabIndex,
        },
        ...subHeaderProperties,
      })}
      <Outlet />
    </MonitorAppContainer>
  );
};

const MonitorWithSuspense = () => {
  return (
    <DfThemeConfigProvider>
      <Suspense fallback={<LoadingSpinner />}>
        <MonitorProvider>
          <Monitor />
        </MonitorProvider>
      </Suspense>
    </DfThemeConfigProvider>
  );
};

MonitorWithSuspense.CAPABILITIES = CAPABILITIES;
MonitorWithSuspense.CAPABILITIES_LABEL_MAP = CAPABILITIES_LABEL_MAP;

export default MonitorWithSuspense;
