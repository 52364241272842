import { getWebsocketState, sendToSocket } from '@/services/sockets';
import { CaretDownFilled, WarningOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Flex, Skeleton, Tooltip } from 'antd';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'umi';

import Logomark from '@/assets/logomark';
import { DfThemeConfigProvider } from '@/layouts/DfThemeConfigProvider';
import type { DfApp } from '@/utils/dfApps';
import { withDfApps } from '@/utils/dfApps';
import { withDfMediaQuery } from '@/utils/dfMediaQuery';
import { getCurrentCustomerID, isInternalUser } from '@/utils/utils';
import withRouter from '@/utils/withRouter';
import { DfDropdown } from '../DfDropdown';
import { DF_SUB_HEADER_DOM_NODE_ID } from './constants';
import {
  DfHeaderContainer,
  DfHeaderPrimary,
  DfSubHeaderContainer,
  HeaderDropdownLogoutLink,
  HeaderDropdownMenuLink,
  HeaderDropdownTriggerContainer,
  NotificationSettingsContainer,
  NotificationSettingsIcon,
  NotificationSettingsText,
  ProfileDropdownTriggerContainer,
  ProfileDropdownTriggerIcon,
  ProfileDropdownTriggerText,
} from './styles';

// @ts-expect-error
@connect(({ user, loading }) => ({
  currentUser: user.currentUser,
  loading,
  areNotificationsMute: user.areNotificationsMute,
}))
class AppHeader extends React.Component {
  state = {
    websocketState: null,
  };

  componentDidMount() {
    const fn = () => {
      // these pings are 'keepalive' messages required for chrome-based
      // browsers; they're not required for safari browsers. without this,
      // if the websocket connection glitches, the browser doesn't know that
      // the connection was broken, and doesn't try to reconnect
      sendToSocket({ type: 'ping' });
      const websocketState = getWebsocketState();
      if (websocketState !== this.state.websocketState) {
        this.setState({ websocketState: getWebsocketState() });
      }
    };
    fn();
    this.pollInterval = setInterval(fn, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.pollInterval);
  }

  openFreshDesk() {
    if (FreshworksWidget) {
      FreshworksWidget('open', 'ticketForm');
    }
  }

  render() {
    const { currentUser, areNotificationsMute } = this.props;
    const firstName = currentUser?.FirstName;
    const internalUser = isInternalUser(currentUser);
    const currentCustomerID = getCurrentCustomerID();
    const currentCustomer = currentUser?.Customers[currentCustomerID].Customer;
    const areAppsLoading = this.props.loading.effects['apps/fetchAllApps'];

    const profileDropdownTriggerContent = (
      <ProfileDropdownTriggerContainer gap={8} align="center">
        <ProfileDropdownTriggerIcon />
        <ProfileDropdownTriggerText>
          {firstName || 'Account'}
          {internalUser ? `/${currentCustomer.Name}` : ''}
        </ProfileDropdownTriggerText>
      </ProfileDropdownTriggerContainer>
    );

    const notificationSettings = (
      <NotificationSettingsContainer
        gap={8}
        align="center"
        onClick={() => {
          this.props.dispatch({
            type: 'user/toggleNotificationMuteSettings',
          });
        }}>
        <NotificationSettingsIcon isMuted={areNotificationsMute} />
        <NotificationSettingsText>
          {areNotificationsMute ? 'Off' : 'On'}
        </NotificationSettingsText>
      </NotificationSettingsContainer>
    );

    const profileDropdownMenuItems: MenuProps['items'] = [
      {
        label: (
          <HeaderDropdownMenuLink
            to="/account/settings"
            isHighLighted={
              this.props.location.pathname === '/account/settings'
            }>
            Account
          </HeaderDropdownMenuLink>
        ),
        key: 'settings',
      },
      {
        label: (
          <HeaderDropdownMenuLink
            to="/account/users"
            isHighLighted={this.props.location.pathname === '/account/users'}>
            Users
          </HeaderDropdownMenuLink>
        ),
        key: 'users',
      },
      {
        label: (
          <HeaderDropdownMenuLink
            to="/account/licenses"
            isHighLighted={
              this.props.location.pathname === '/account/licenses'
            }>
            Licences
          </HeaderDropdownMenuLink>
        ),
        key: 'licenses',
      },
      {
        label: <HeaderDropdownMenuLink to="#">Support</HeaderDropdownMenuLink>,
        onClick: this.openFreshDesk,
        key: 'support',
      },
      {
        label: (
          <HeaderDropdownLogoutLink to="#">Logout</HeaderDropdownLogoutLink>
        ),
        onClick: () => {
          this.props.dispatch({
            type: 'user/logout',
          });
        },
        key: 'logout',
      },
    ].filter((item) => {
      // allowing the links for /account/users and /account/licenses only for DF internal users
      if (!internalUser) {
        return !['users', 'licenses'].includes(item.key);
      }

      return true;
    });

    const headerDropdownSkeleton: MenuProps['items'] = new Array(5)
      .fill(undefined)
      .map((_, index) => {
        return {
          label: (
            <Skeleton.Input
              style={{ width: this.props.isMobile ? '260px' : '160px' }}
              size="default"
              active
            />
          ),
          key: `skeleton-${index}`,
        };
      });

    const headerDropdownMenuItems: MenuProps['items'] = areAppsLoading
      ? headerDropdownSkeleton
      : [
          {
            label: (
              <HeaderDropdownMenuLink
                to="/home"
                isHighLighted={this.props.location.pathname === '/home'}>
                Home
              </HeaderDropdownMenuLink>
            ),
            key: 0,
          },
          ...(this.props.apps as DfApp[]).map(({ name, pathname }, index) => {
            return {
              label: (
                <HeaderDropdownMenuLink
                  to={pathname}
                  isHighLighted={this.props.location.pathname.startsWith(
                    pathname,
                  )}>
                  {name}
                </HeaderDropdownMenuLink>
              ),
              key: index + 1,
            };
          }),
        ];

    return (
      <DfThemeConfigProvider>
        <DfHeaderContainer>
          <DfHeaderPrimary>
            <DfDropdown
              menu={{ items: headerDropdownMenuItems }}
              trigger={['click']}>
              <HeaderDropdownTriggerContainer align="center" gap={8}>
                <Logomark width="181px" height="28px" />
                <CaretDownFilled />
              </HeaderDropdownTriggerContainer>
            </DfDropdown>
            <Flex gap={8}>
              {(!window.navigator.onLine ||
                this.state.websocketState !== 1) && (
                <Tooltip title="Disconnected from Cloud. Reconnecting...">
                  <WarningOutlined />
                </Tooltip>
              )}
              {notificationSettings}
              <DfDropdown
                menu={{ items: profileDropdownMenuItems }}
                trigger={['click']}>
                {profileDropdownTriggerContent}
              </DfDropdown>
            </Flex>
          </DfHeaderPrimary>
          <DfSubHeaderContainer id={DF_SUB_HEADER_DOM_NODE_ID} />
        </DfHeaderContainer>
      </DfThemeConfigProvider>
    );
  }
}

export default compose(withDfMediaQuery, withDfApps, withRouter)(AppHeader);
