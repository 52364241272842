import { EditFilled, StarFilled } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import styled from 'styled-components';

const { Text, Paragraph } = Typography;

export const SceneCardContainer = styled.div(
  ({ theme }) => `
  position: relative;
  display: flex;
  flex-direction: column;
  width: 335px;
  height: 270px;
  border-radius: ${theme.token?.borderRadiusLG}px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
  background-color: ${theme.token?.colorBgContainer};
  overflow: hidden;
`,
);

export const SceneDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 30px;
  justify-content: space-between;
  height: 124px;
  width: 100%;
`;

export const SceneChannelThumbnailsContainer = styled.div`
  height: 150px;
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
`;

export const ScreenDetailsTitleText = styled(Paragraph)`
  font-size: 16px;
  max-width: 85%;
  line-height: 24px;
`;

export const SceneDetailsTimestampText = styled(Text)(
  ({ theme }) => `
  font-size: 12px;
  color: ${theme.token?.colorTextSecondary};
  font-weight: 400;
`,
);

export const SceneStarContainer = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
`;

export const ShareUnshareIconContainer = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 2px;
  border-radius: 2px;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

export const ThumbnailBackground = styled.div<{
  url: string;
  allThumbnailsCount: number;
}>`
  background-image: url(${({ url }) => url});
  background-size: cover;
  background-position: center;
  flex: 1;
  border: 0.5px solid #f0f0f0;
  background-color: #6c757d; // If no background image is displayed thru URL, this color is displayed

  min-width: ${({ allThumbnailsCount }) => {
    if (allThumbnailsCount < 5) {
      return '50%';
    }

    if (allThumbnailsCount < 7) {
      return '33.33%';
    }

    return '25%';
  }};
`;

export const SceneStarredIcon = styled(StarFilled)`
  color: #ff9301;
  cursor: pointer;

  > svg {
    width: 16px;
    height: 16px;
  }
`;

export const SceneUnStarredIcon = styled(StarFilled)(
  ({ theme }) => `
  color: ${theme.token?.colorBgLayout};
  cursor: pointer;

  > svg {
    width: 16px;
    height: 16px;
  }
`,
);

export const SceneTitleNameEditIcon = styled(EditFilled)(
  ({ theme }) => `
  color: ${theme.token?.colorTextSecondary};
  cursor: pointer;
  margin-left: 4px;
  transform: translateY(2px);

  > svg {
    width: 14px;
    height: 14px;
  }
`,
);

export const SceneSharingErrorText = styled(Typography.Text)`
  width: min-content;
  min-width: 100%;
`;

export const SceneSharingPopoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 14px;
`;

export const SceneSharingButton = styled(Button)`
  padding: 0;
  height: auto;
`;

export const SceneSharingDropdownText = styled(Typography.Text)<{
  disabled?: boolean;
  variant?: 'primary' | 'danger';
}>(
  ({ theme, disabled, variant = 'primary' }) => `
  font-size: 16px;
  font-weight: 400;
  color: ${
    variant === 'danger' ? theme.token?.colorError : theme.token?.colorText
  };
  opacity: ${disabled ? 0.5 : 1};
  text-wrap: wrap;
  line-height: 20px;
`,
);

export const SceneSharingDropdownErrorText = styled(Typography.Text)(
  ({ theme }) => `
  font-size: 14px;
  font-weight: 400;
  color: ${theme.token?.colorError};
  text-wrap: wrap;
  line-height: 20px;
`,
);
