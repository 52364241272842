import { SiteSelectionCascader } from '@/components/SiteSelectionCascader';
import type { Site } from '@/components/SiteSelectionCascader/types';
import { SearchOutlined } from '@ant-design/icons';
import { Checkbox, Input, Typography } from 'antd';
import React from 'react';
import type { UseQueryLoaderLoadQueryOptions } from 'react-relay';
import { useLazyLoadQuery } from 'react-relay';
import { useMonitorContext } from '../../../../MonitorContext';
import { AllSitesAndLabelsQuery } from '../../../../MonitorQueries';
import type { MonitorQueries_AllSitesAndLabels_Query } from '../../../../__generated__/MonitorQueries_AllSitesAndLabels_Query.graphql';
import type { MonitorQueries_SitesWithChannels_Query$variables } from '../../../../__generated__/MonitorQueries_SitesWithChannels_Query.graphql';
import { SiteSelectionContainer } from './styles';

const getSelectedSitesLocalStorageKey = (customerId: number) =>
  `${customerId}.vms_plus.camera_selection.filtered_sites`; // we are appending the customer id to make the localstorage key unique to avoid clashes when a user logs into multiple customer accounts on same browser

type SiteSelectionProps = {
  setSidebarSearch: React.Dispatch<React.SetStateAction<string>>;
  showInactiveChannels: boolean;
  handleIncludeInactiveToggled: () => void;
  loadSitesWithChannelsQuery: (
    variables: MonitorQueries_SitesWithChannels_Query$variables,
    options?: UseQueryLoaderLoadQueryOptions,
  ) => void;
  existingSiteIds?: string[];
};

const SiteSelection = ({
  loadSitesWithChannelsQuery,
  setSidebarSearch,
  existingSiteIds,
  showInactiveChannels,
  handleIncludeInactiveToggled,
}: SiteSelectionProps) => {
  const { appId, customerId } = useMonitorContext();

  const allSitesData = useLazyLoadQuery<MonitorQueries_AllSitesAndLabels_Query>(
    AllSitesAndLabelsQuery,
    {
      app_id: appId,
      customer_id: customerId,
    },
  );

  const handleSiteSelectionChange = (value: string[]) => {
    loadSitesWithChannelsQuery({
      app_id: appId,
      customer_id: customerId,
      filter_site_ids: value,
    });
    localStorage.setItem(
      getSelectedSitesLocalStorageKey(customerId),
      value.join(','),
    );
  };

  const handleSidebarSearchChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = e.target.value;
    setSidebarSearch(value?.toLowerCase() ?? '');
  };

  return (
    <SiteSelectionContainer>
      <Typography.Title level={5} style={{ width: '100%' }}>
        Add Cameras
      </Typography.Title>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          alignItems: 'center',
          gap: '10px',
        }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'flex-end',
            gap: '10px',
          }}>
          <SiteSelectionCascader
            sites={
              allSitesData.sites?.edges.map((site) => site?.node) as Site[]
            }
            siteGroups={
              allSitesData.labels?.edges.map((label) => ({
                LabelID: label?.node?.LabelID,
                Name: label?.node?.Name,
                Color: label?.node?.Color,
                LabelType: label?.node?.LabelType,
                Sites: label?.node?.Sites?.edges.map(
                  (site) => site?.node,
                ) as Site[],
              })) as []
            }
            existingSelectedSites={existingSiteIds}
            onChange={(options) => {
              const siteIds = options.map(
                (option) => option[option.length - 1],
              ) as string[];
              handleSiteSelectionChange(siteIds);
            }}
            placeholder="Select sites"
            maxTagCount={2}
          />
        </div>
        <Input
          placeholder="Find by name..."
          suffix={<SearchOutlined />}
          onChange={handleSidebarSearchChange}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '8px',
          justifyContent: 'left',
          width: '100%',
        }}>
        <Checkbox
          checked={showInactiveChannels}
          onChange={handleIncludeInactiveToggled}
        />
        <Typography>Show inactive cameras</Typography>
      </div>
    </SiteSelectionContainer>
  );
};

export { SiteSelection };
